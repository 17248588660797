/**
 * Calculates the increment percentage from a number to another
 * @param from The number to calculate the increment percentage from
 * @param to The number to calculate the increment percentage to
 * @returns The increment percentage from from to to
 */
export const incrementPercentage = (from: number, to: number, decimal = 2): number => {
	if (!from || !to) return 0;
	const inc = +to - +from;
	return +((+inc / +from) * 100).toFixed(decimal);
};

/**
 * Formats a number to a string with specified decimal places using locale-specific formatting
 * @param number The number to format
 * @param decimalPlaces The number of decimal places to round to (default: 2)
 * @returns The formatted number as a locale-specific string
 */
export const formatToString = (number: number, decimalPlaces = 2) => {
	const factor = Math.pow(10, decimalPlaces);
	return (Math.round(number * factor) / factor).toLocaleString();
};
